import EasyPaymentServiceApi from '@/services/api/EasyPaymentService'

import nacl from 'tweetnacl'
import {
  decodeUTF8,
  encodeUTF8,
  encodeBase64,
  decodeBase64
} from 'tweetnacl-util'
nacl.sealedbox = require('tweetnacl-sealedbox-js')

import moment from 'moment'

//const baseUrl = 'http://localhost:8001'
//const baseUrl = 'https://www.cmsevents.ch'

const encryptSealedData = (key, data) => {
  return encodeBase64(nacl.sealedbox.seal(decodeUTF8(data), decodeBase64(key)))
}
const encryptData = (key, data) => {
  let nonce = nacl.randomBytes(nacl.secretbox.nonceLength)
  return encodeBase64(nacl.secretbox(decodeUTF8(data), nonce, decodeBase64(key))) + '|' + encodeBase64(nonce)
}
const decryptData = (key, data) => {
  let d = data.split('|')
  return encodeUTF8(nacl.secretbox.open(decodeBase64(d[0]), decodeBase64(d[1]), decodeBase64(key)))
}
const generateKey = () => {
    return encodeBase64(nacl.randomBytes(nacl.secretbox.keyLength))
}

const EasyPaymentService = {
    getPayment(id, pk) {
        return EasyPaymentServiceApi.get(id)
            .then(r => r.data)
            .then(i => {
                if(pk)
                    return ({
                        id: i.payment_hash,
                        date: i.date ? moment(decryptData(pk, i.date)) : '',
                        name: i.recipient ? decryptData(pk, i.recipient) : '',
                        usage: i.title ? decryptData(pk, i.title) : '',
                        description: i.description ? decryptData(pk, i.description) : '',
                        amount: Number(i.price) || 0,
                        status: i.status
                    })
                else
                    return null
            })
    },

    savePayment(paymentHash, name, usage, amount, key, event) {
        if(paymentHash == 'new') {
            let k = generateKey()
            let data = {
                event: event,
                key: encryptSealedData(key, k), 
                recipient: name ? encryptData(k, name) : '',
                title: usage ? encryptData(k, usage) : '',
                date: encryptData(k, moment().toISOString()),
                price: amount ? amount : '',
            }
            return EasyPaymentServiceApi.easyProcess('new', data)
                .then(res => res.data)
        } else {
            let data = {
                recipient: name ? encryptData(key, name) : '',
                title: usage ? encryptData(key, usage) : '',
                price: amount ? amount : '',
            }
            return EasyPaymentServiceApi.easyProcess(paymentHash, data)
                .then(res => res.data)
        }
    },
}


export default EasyPaymentService
