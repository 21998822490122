import http from './http-common'

const get = (id) => {
    return http.get(`api/payment/${id}`)
}
const easyProcess = (id, data) => {
    return http.post(`api/payment/${id}/easyProcess`, data)
}

export default {
    get,
    easyProcess
}
