<template>
  <div class="wrapper">
    <div class="section page-header header-filter">
      <div class="container">
        <div class="md-layout">
          <div class="md-layout-item md-size-33 md-small-size-66 md-xsmall-size-100 md-medium-size-40 mx-auto" >
            <login-card header-color="primary">
              <h4 slot="title" class="card-title">Zahlung an Club-CMS</h4>
              
              <div slot="description">
                <p class="description pay-info-text">Sie bezahlen Ihren persönlichen Eintritt.</p>
                <p class="event-usage-text">{{ usage }}</p>
                <p class="description event-date"><b class="date-prefix">Datum: </b>{{ date | moment("DD. MMMM YYYY")}}</p>
                <div class="price">
                <p class="description event-price"><b class="price-prefix">Preis: </b> {{ amount }} CHF</p>
                </div>
                <p class="event-description">{{ description }}</p>
                <p class="description event-change-field-text" v-if="(!status || status < 100) && showPaymentButton">Bitte ergänzen Sie die untenstehenden Felder.</p>
              </div>
              
              <md-field slot="inputs" >
                <label>Name</label>
                <md-input v-model="name" :readonly="status >= 100"></md-input>
              </md-field>
              <md-field slot="inputs" v-if="editUsage">
                <label>Verwendung</label>
                <md-input v-model="usage" :readonly="status >= 100"></md-input>
              </md-field>
              <md-field slot="inputs" v-if="editAmount">
                <label>Betrag</label>
                <md-input v-model="amount" :readonly="status >= 100"></md-input>
                <span class="md-suffix">CHF</span>
              </md-field>

              <div slot="footer" class="md-success md-lg paybuttons" v-if="(!status || status < 100) && showPaymentButton">
                <div class="paybutton" @click="pay('mastervisa')">
                  <img class="paybutton-image" src="/img/mastercard_visa.svg"> 
                </div>
                <div class="paybutton" @click="pay('twint')">
                  <img class="paybutton-image" src="/img/twint.svg">
                </div>
                <div class="paybutton" @click="pay('paypal')">
                  <img class="paybutton-image" src="/img/paypal.svg">
                </div>
              </div>

              <div slot="footer" class="md-success md-lg" v-else-if="status >= 100">
                Bereits bezahlt
              </div>
              <div slot="footer" class="md-success md-lg fullwidth" v-else-if="source">
                <iframe class="payment-frame" :src="source" title="Payment Iframe"></iframe> 
              </div>
              <div slot="footer" class="md-success md-lg" v-else>
                Lade Daten...
              </div>
               
            </login-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoginCard from '@/components/LoginCard';
import EasyPaymentService from '@/services/EasyPaymentService'
export default {
  components: {
    LoginCard,
  },
  data() {
    return {
      id: '',
      name: '',
      usage: '',
      amount: 0,
      event: null,
      key: '',
      editUsage: false,
      editAmount: false,
      status: null,
      source: '',
      showPaymentButton: true,
      title: '',
      description: '',
      date: ''
      
    };
  },
  methods: {
    setData(code) {
      this.event = null
      this.status = 0
      code = code.split('|')
      if(code.length == 2) {
        if(code[0] == 'TEMPLATE') {
          var template = code[1].split('_');
          this.id = 'new'
          this.name = ''
          this.usage = atob(template[1].replace(/\$/g,'/'))
          if(template[0] == 'EVENT') {
            this.event = atob(template[2].replace(/\$/g,'/'))
            this.key = atob(template[3].replace(/\$/g,'/'))
            this.amount = template[4] || 0
          } else {
            this.key = atob(template[2].replace(/\$/g,'/'))
            this.amount = template[3] || 0
          }

          this.editAmount = !this.amount
        } else {
          this.key = code[1].replace(/\$/g,'/');
          EasyPaymentService.getPayment(code[0], this.key)
            .then(r => {
              this.id = r.id
              this.name = r.name
              this.usage = r.usage
              this.amount = r.amount
              this.status = r.status
              this.title = r.title
              this.description = r.description
              this.date =  r.date || new Date()
              this.editUsage = !this.usage
              this.editAmount = !this.amount
            })
        }
      }
    },
    pay(paymentMethod) {
      this.showPaymentButton = false
      EasyPaymentService.savePayment(this.id, this.name, this.usage, this.amount, this.key, this.event)
        .then(i => {
          if(i.price && i.id){
            if('visa'=== paymentMethod){
              this.source = process.env.VUE_APP_PAYMENT_LINK_VISA.replace('{id}', i.id).replace('{price}', i.price * 100).replace('{hmac}', i.hmac)
            } else if('mastercard'=== paymentMethod){
              this.source = process.env.VUE_APP_PAYMENT_LINK_MASTERCARD.replace('{id}', i.id).replace('{price}', i.price * 100).replace('{hmac}', i.hmac)
            }else if('mastervisa'=== paymentMethod){
              this.source = process.env.VUE_APP_PAYMENT_LINK_MASTERVISA.replace('{id}', i.id).replace('{price}', i.price * 100).replace('{hmac}', i.hmac)
            } else if('twint'=== paymentMethod){
              this.source = process.env.VUE_APP_PAYMENT_LINK_TWINT.replace('{id}', i.id).replace('{price}', i.price * 100).replace('{hmac}', i.hmac) 
            }else if('paypal'=== paymentMethod){
              this.source = process.env.VUE_APP_PAYMENT_LINK_PAYPAL.replace('{id}', i.id).replace('{price}', i.price * 100).replace('{hmac}', i.hmac) 
            } else{
              this.source = process.env.VUE_APP_PAYMENT_LINK.replace('{id}', i.id).replace('{price}', i.price * 100).replace('{hmac}', i.hmac)
            }

            
          }else {
              if (i.error == 'allready payed')
                this.status = 300 // fake dataointer: cursor
              else
                alert('Fehler beim Bezahlvorgang: ' + i.error)
          }
        })
        //.catch(() => alert('Fehler beim Laden des Zahlungsportals'))
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.setData(to.params.code))
  },
  beforeRouteUpdate(to, from, next) {
    this.setData(to.params.code)
    next()
  },
  props: {
  },
  computed: {
  }
};
</script>

<style lang="css">

  /* md-ripple padding wird hier aufgehoben durch minus margin des child containers */
  .paybutton .md-ripple .md-button-content{
    margin: -1.125rem -2.25rem;
  }
  .paybuttons{
    display: flex !important;
    margin-bottom: 1.5rem;
  }
  .paybutton{
    flex: 1 1 0px;
    cursor: pointer;
  }
  .paybutton-image{
    width: 100%;
  }
  
  .fullwidth {
    width: 100%;
  }
  iframe.payment-frame {
    height: 50vh;
    width: 100%;
  }

  .pay-info-text{
    margin-bottom: 30px;
  }
  .event-usage-text{
    text-align: left;
    color: black;
    font-weight: bold;
    font-size: 1.1em;
    margin-bottom: 0;
  }
  .date-prefix {
    font-weight: bold;
  }
  .price-prefix{
    font-weight: bold;
  }
  .event-date{
    text-align: left !important;
    margin: 0;
  }
  .event-price{
    text-align: left !important;
    margin: 0;
  }

  .event-description{
    white-space: pre;
    text-align: left;
    color: black;
    margin-bottom: 30px;
  }
  .event-change-field-text{
    margin-bottom: 20px;
  }
</style>
